import React from 'react';
import './About.css';

const AboutUs = () => {
    return (
        <div className="about-us page-background">
            <div className="about-us-content">
                <h1>About Us</h1>
                <p>Welcome to Rodebush Company LLC, your trusted partner in the procurement of high-quality equipment and services tailored to meet your unique needs. At Rodebush Company LLC, we understand that every project is distinct, and we pride ourselves on offering customized solutions that drive success.</p>
                <h2>Our Mission</h2>
                <p>Our mission is to streamline your procurement process by providing top-tier equipment and services that align perfectly with your objectives. We aim to build long-lasting relationships with our clients through reliability, innovation, and unparalleled customer service.</p>
                <h2>Who We Are</h2>
                <p>Rodebush Company LLC was founded on the principles of integrity, excellence, and dedication. Our team comprises industry experts with years of experience, committed to delivering exceptional value in every interaction. We leverage our extensive network and in-depth market knowledge to source the best products and services for our clients.</p>
                <h2>What We Do</h2>
                <p>We specialize in understanding your specific requirements and delivering solutions that not only meet but exceed your expectations. Whether you need advanced machinery, specialized tools, or comprehensive service packages, we have the expertise to fulfill your demands efficiently and cost-effectively.</p>
                <h2>Why Choose Us</h2>
                <p><strong>Expertise:</strong> Our team is composed of seasoned professionals who bring a wealth of knowledge and experience to the table.</p>
                <p><strong>Quality:</strong> We ensure that every product and service we provide meets the highest standards of quality and performance.</p>
                <p><strong>Customer-Centric:</strong> Your satisfaction is our priority. We work closely with you to understand your needs and deliver solutions that fit.</p>
                <p><strong>Reliability:</strong> Count on us for timely delivery and consistent performance that supports your operational goals.</p>
                <p>Join us at Rodebush Company LLC and experience a seamless procurement process that empowers your business to thrive. We are here to support your success every step of the way.</p>
            </div>
        </div>
    );
}

export default AboutUs;
