import React from 'react';
import './Contact.css';

document.addEventListener("DOMContentLoaded", function() {
  const form = document.querySelector("form");
  form.addEventListener("submit", (event) => {
    // prevent the form submit from refreshing the page
    event.preventDefault();

    const { name, email, phone, subject, message } = event.target;

    // Use your API endpoint URL
    const endpoint = "https://vtz1sb61za.execute-api.us-east-1.amazonaws.com/default/RC-ContactUs-Email";
    // We use JSON.stringify here so the data can be sent as a string via HTTP
    //const body = JSON.stringify({
     // senderName: name.value,
     // email: email.value,
     // phone: phone.value,
     // subject: subject.value,
     // message: message.value
    //});

    //window.alert("Body is " + body);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        "Access-Control-Allow-Credentials": true // Required for cookies, authorization headers with HTTPS
      },
      body: JSON.stringify({
        senderName: name.value,
        email: email.value,
        phone: phone.value,
        subject: subject.value,
        message: message.value
      })
    };
    window.alert("Body is " + requestOptions.body);

    fetch(endpoint, requestOptions)
      .then((response) => {
        if (!response.ok) throw new Error("Error in fetch");
        return response.json();
      })
      .then((response) => {
        document.getElementById("result-text").innerText =
          "Email sent successfully!";
      })
      .catch((error) => {
        document.getElementById("result-text").innerText =
          "An unknown error occurred.";
      });
  });
});


function Contact() {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <form>
        <label>
          Name:
          <input type="text" name="name" required />
        </label>
        <label>
          Email:
          <input type="email" name="email" required />
        </label>
        <label>
          Phone:
          <input type="tel" name="phone" required />
        </label>
        <label>
          Subject:
          <input type="text" name="subject" required />
        </label>
        <label>
          Message:
          <textarea name="message" required></textarea>
        </label>
        <button type="submit">Submit</button>
      </form>
      <div id="result-text"></div>
      <script src="./contact.js"></script>
    </div>
  );
}

export default Contact;
