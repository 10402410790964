import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services">
      <div className="services-content">
        <h2>Our Services</h2>
        <p>
          At Rodebush Company LLC, we offer a comprehensive range of procurement services designed to meet the diverse needs of our clients. Our commitment to quality and customer satisfaction drives us to deliver exceptional solutions that ensure your success. Here’s a closer look at the services we provide:
        </p>
        <h3>1. Equipment Procurement</h3>
        <p>
          We specialize in sourcing high-quality equipment tailored to your specific requirements. Our extensive network and industry expertise allow us to procure products and services that enhance your operational efficiency and productivity.
        </p>
        <h3>2. Custom Solutions</h3>
        <p>
          Understanding that every project is unique, we provide customized procurement solutions that align with your objectives. From specialized tools to bespoke machinery, we ensure you receive equipment that fits perfectly with your needs.
        </p>
        <h3>3. Comprehensive Service Packages</h3>
        <p>
          In addition to equipment procurement, we offer comprehensive service packages that include installation, maintenance, and support. Our end-to-end solutions are designed to keep your operations running smoothly and efficiently.
        </p>
        <h3>4. Vendor Management</h3>
        <p>
          We handle all aspects of vendor management, ensuring you receive the best products and services from trusted suppliers. Our team conducts thorough evaluations and negotiations to secure favorable terms and reliable partnerships.
        </p>
        <h3>5. Cost Optimization</h3>
        <p>
          We are dedicated to helping you achieve cost savings without compromising on quality. Our strategic procurement practices and in-depth market knowledge enable us to identify the best value options, optimizing your procurement budget.
        </p>
        <h3>6. Timely Delivery</h3>
        <p>
          We understand the importance of timely delivery in maintaining your project schedules. Our efficient logistics and supply chain management ensure that your products and services are delivered on time, every time.
        </p>
        <h3>7. Ongoing Support</h3>
        <p>
          Our relationship with clients extends beyond procurement. We provide ongoing support and consultation to address any challenges you may face. Our team is always available to assist with troubleshooting, upgrades, and any other needs.
        </p>
        <h3>8. Industry Expertise</h3>
        <p>
          With years of experience across various industries, our team brings invaluable insights and expertise to your procurement process. We stay updated on the latest trends and advancements to offer you the most innovative solutions.
        </p>
        <p>
          Choose Rodebush Company LLC for reliable, efficient, and cost-effective procurement services that propel your business forward. Partner with us and experience a seamless procurement process designed to support your success.
        </p>
      </div>
    </div>
  );
}

export default Services;
