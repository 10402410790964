import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/DarkLogo.svg';
import './Header.css';

const Header = ({ toggleMenu }) => {
  return (
    <header className="App-header">
      <Link to="/" className="App-logo-link">
        <img src={logo} className="App-logo" alt="logo" />
      </Link>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
    </header>
  );
}

export default Header;
