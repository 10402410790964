import React from 'react';
import './Home.css';
import logo from '../assets/DarkLogo.svg';
import handshake from '../assets/Handshake.png';

const Home = ({ toggleMenu }) => {
  return (
    <div className="home-container">
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className="home-logo">
        <img src={logo} alt="Rodebush Company LLC" />
      </div>
      <div className="home-image">
        <img src={handshake} alt="Handshake" />
      </div>
    </div>
  );
}

export default Home;
