import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="App-footer">
      <ul className="App-footer-nav">
        <li><Link to="/privacy">Privacy Policy</Link></li>
        <li><Link to="/terms">Terms and Conditions</Link></li>
      </ul>
      <p>&copy; 2024 Rodebush Company LLC. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
